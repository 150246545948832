(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/customer-service-smart-faq/views/start.js') >= 0) return;  svs.modules.push('/modules/customer-service-smart-faq/views/start.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.customer_service_smart_faq=_svs.customer_service_smart_faq||{};
_svs.customer_service_smart_faq.templates=_svs.customer_service_smart_faq.templates||{};
svs.customer_service_smart_faq.templates.start = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<iframe id=\"smartfaq\" class=\"smartfaq\" src=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"smartFaqUrl") || (depth0 != null ? lookupProperty(depth0,"smartFaqUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"smartFaqUrl","hash":{},"data":data,"loc":{"start":{"line":1,"column":44},"end":{"line":1,"column":61}}}) : helper))) != null ? stack1 : "")
    + "\" sandbox=\"allow-scripts allow-same-origin allow-presentation allow-forms allow-popups allow-top-navigation-by-user-activation allow-popups-to-escape-sandbox allow-downloads\"></iframe>\n";
},"useData":true});
Handlebars.partials['svs-customer_service_smart_faq-start'] = svs.customer_service_smart_faq.templates.start;
})(svs, Handlebars);


 })(window);